<template>
  <div>
    <router-link
    class="event-link"
    :to="{ name: 'EventDetails', params: { id: event.id } }">
      <div class="event-card">
        <!-- Event data! -->
        <span>
          @ {{ event.time }} on {{ event.date }}
          <h4>
            {{ event.title }}
          </h4>
        </span>
      </div>
    </router-link>
  </div>
</template>

<script>
export default {
  name: "Card",
  props: {
    event: Object,
  },
};
</script>


<style scoped>

.event-card {
  padding: 20px;
  width: 250px;
  cursor: pointer;
  border: 1px solid #39495c;
  margin-bottom: 18px;
}

.event-link {
  text-decoration: none;
  color: #2c3e50;
}

</style>
